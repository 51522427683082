<template>
  <div class="rocket-game-container layout-border">
    <audio :src="audioUrl" ref="audioBox"></audio>
    <div class="customlesson-content-five position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <div class="picture-one" ref="pictureOne" id="space-background-scroll">
        <img ref="content" :src="bcgImg" alt="" />
        <div
          ref="rocketTop"
          class="picture-content-rocket-content"
          :class="{ fadeOutRocket: fadeOutRocket }"
        >
          <img
            class="rocket"
            src="@/assets/img/16-GAMES/G09-rocket/rocket.svg"
            alt=""
          />
          <img
            class="rocket-flame"
            :class="{
              rocketFlameAnimated: rocketFlameAnimated,
              isHidden: !showFlames,
            }"
            src="@/assets/img/16-GAMES/G09-rocket/rocket-flame.svg"
            alt=""
          />
        </div>
      </div>
      <div class="picture-bottom">
        <img src="@/assets/img/16-GAMES/G09-rocket/control-table.svg" alt="" />
        <!-- <div class="picture-bottom-rocket-bottom" v-if="control == 0">
          <img src="@/assets/img/16-GAMES/G09-rocket/rocket.svg" alt=""/>
        </div> -->
        <!-- <div :class="control == 1 || control == 3?'picture-bottom-control-button-bottom':'picture-bottom-control-button-top'" @click="controlClick">
          <img src="@/assets/img/16-GAMES/G09-rocket/control.svg" alt=""/>
        </div> -->
        <div
          ref="buttonTop"
          class="picture-bottom-control-button-top glow"
          @click="controlClick"
        >
          <img src="@/assets/img/16-GAMES/G09-rocket/control.svg" alt="" />
        </div>
      </div>
      <!-- 下面左边图片 -->
    </div>
    <!-- 手指的位置 -->
    <div class="customlesson-finger" v-if="control == 0">
      <img src="@/assets/img/16-GAMES/G09-rocket/hand.svg" alt="" />
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    bcgImg: {
      type: String,
      require: true,
    },
  },
  components: {
    PageButton,
  },
  data() {
    return {
      control: 0,
      scrollTop: 0,
      hoverClass: 1,
      numBers: 1,
      fontSizeSum: " ",
      hoverClassOne: 1,
      numBersOne: 1,
      pictureWidth: {},
      numBersBlooFree: false,
      numBersBlooTwo: false,
      numBersBloo: false,
      rocketFlameAnimated: false,
      fadeOutRocket: false,
      showFlames: false,
      controlClickNum: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
      audioUrl: require("@/assets/audio/chapter_1/lesson_4/rocket-sound.mp3"),
    };
  },
  watch: {
    scrollTop() {},
    controlClickNum() {
      const data = {
        clickType: 20009,
        data: { value: null },
        text: "rocketGame按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$refs.pictureOne.scrollTop = this.$refs.content.scrollHeight;
      this.scrollTop = this.$refs.pictureOne.scrollTop;
    }, 50);
    this.$bus.$on("clickRocketGame", () => {
      this.controlClick(1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickRocketGame");
  },
  methods: {
    scrollDefault() {
      if (this.control == 0) {
        this.$refs.pictureOne.scrollTop = this.$refs.content.scrollHeight;
        this.scrollTop = this.$refs.pictureOne.scrollTop;
      }
    },
    controlClick(type) {
      if (type != 1) {
        this.controlClickNum++;
      }
      this.$refs.buttonTop.classList.remove("glow");
      let buttonTop = this.$refs.buttonTop;
      let rocketTop = this.$refs.rocketTop;
      // let rocketBottom = this.$refs.rocketBottom;
      let backgroundScrollTop = this.$refs.pictureOne;
      let backgroundCurrTop;
      this.control += 1;
      this.numBers += 1;
      if (this.control == "1") {
        this.showFlames = true;
        //if (this.control == 1) {
        let num = 39;
        let numOne = -0; //initial position bottom of rocket
        backgroundCurrTop = backgroundScrollTop.scrollHeight * 0.9;
        new Promise((res) => {
          this.timer = setInterval(() => {
            if (numOne <= 25) {
              //move the rocket up, and stop at bottom: 15%
              numOne += 4;
            }
            if (num <= 63) {
              num += 4;
            }
            console.log("==backgroundCurrTop", backgroundCurrTop);
            backgroundCurrTop -= 35;
            buttonTop.style.top = num + "%";
            rocketTop.style.bottom = numOne + "%";
            backgroundScrollTop.scrollTop = backgroundCurrTop;
            this.rocketFlameAnimated = true;
            this.playAudio();
            // calculate the % of the total height, and convert into px for background position
            // first stop is at 30% (70% from top)
            if (backgroundCurrTop <= backgroundScrollTop.scrollHeight * 0.75) {
              clearInterval(this.timer);
              this.rocketFlameAnimated = false;
              this.playAudio(false);
              res();
            }
          }, 50);
        }).then(() => {
          let num = 63;
          let timerOne = setInterval(() => {
            num -= 4;
            buttonTop.style.top = num + "%";
            if (num < 43) {
              clearInterval(timerOne);
              this.control += 1;
            }
          }, 50);
        });
      } else if (this.control == "3") {
        let num = 39;
        let numOne = 85;
        backgroundCurrTop = backgroundScrollTop.scrollHeight * 0.75;

        new Promise((res) => {
          this.timer = setInterval(() => {
            if (numOne >= 25) {
              //move the rocket up, and stop at top: 25%
              numOne -= 1;
            }
            if (num <= 63) {
              num += 4;
            }
            backgroundCurrTop -= 35;
            buttonTop.style.top = num + "%";
            // rocketTop.style.top = numOne + "%";
            backgroundScrollTop.scrollTop = backgroundCurrTop;
            this.rocketFlameAnimated = true;
            this.playAudio();

            // calculate the % of the total height, and convert into px for background position
            // second  stop is at 50% (50% from top)
            if (backgroundCurrTop <= backgroundScrollTop.scrollHeight * 0.5) {
              clearInterval(this.timer);
              this.rocketFlameAnimated = false;
              this.playAudio(false);

              res();
            }
          }, 50);
        }).then(() => {
          let num = 63;
          let timerOne = setInterval(() => {
            num -= 4;
            buttonTop.style.top = num + "%";
            if (num < 43) {
              clearInterval(timerOne);
              this.control += 1;
            }
          }, 50);
        });
      } else if (this.control == 5) {
        let num = 39;
        let numOne = 85;
        backgroundCurrTop = backgroundScrollTop.scrollHeight * 0.5;

        new Promise((res) => {
          this.timer = setInterval(() => {
            if (numOne >= 25) {
              //move the rocket up, and stop at top: 25%
              numOne -= 1;
            }
            if (num <= 63) {
              num += 4;
            }
            backgroundCurrTop -= 35;
            buttonTop.style.top = num + "%";
            // rocketTop.style.top = numOne + "%";
            backgroundScrollTop.scrollTop = backgroundCurrTop;
            this.rocketFlameAnimated = true;
            this.playAudio();
            // calculate the % of the total height, and convert into px for background position
            // second  stop is at 50% (50% from top)
            if (backgroundCurrTop <= backgroundScrollTop.scrollHeight * 0.21) {
              clearInterval(this.timer);
              this.rocketFlameAnimated = false;
              this.playAudio(false);
              res();
            }
          }, 50);
        }).then(() => {
          let num = 63;
          let timerOne = setInterval(() => {
            num -= 4;
            buttonTop.style.top = num + "%";
            if (num < 43) {
              clearInterval(timerOne);
              this.control += 1;
            }
          }, 50);
        });
      } else if (this.control == 7) {
        let num = 39;
        let numOne = 85;
        backgroundCurrTop = backgroundScrollTop.scrollHeight * 0.21;

        new Promise((res) => {
          this.timer = setInterval(() => {
            if (numOne >= 25) {
              //move the rocket up, and stop at top: 25%
              numOne -= 1;
            }
            if (num <= 63) {
              num += 4;
            }
            backgroundCurrTop -= 35;
            buttonTop.style.top = num + "%";
            // rocketTop.style.top = numOne + "%";
            backgroundScrollTop.scrollTop = backgroundCurrTop;
            this.rocketFlameAnimated = true;
            this.playAudio();
            // calculate the % of the total height, and convert into px for background position
            // second  stop is at 50% (50% from top)
            if (backgroundCurrTop <= backgroundScrollTop.scrollHeight * 0.0) {
              clearInterval(this.timer);
              this.rocketFlameAnimated = false;
              this.playAudio(false);
              res();
            }
          }, 50);
        }).then(() => {
          let num = 63;
          let timerOne = setInterval(() => {
            num -= 4;
            buttonTop.style.top = num + "%";
            if (num < 43) {
              clearInterval(timerOne);
              this.control += 1;
            }
          }, 50);
        });
      } else if (this.control == 7) {
        let num = 39;
        let numOne = 85;
        backgroundCurrTop = backgroundScrollTop.scrollHeight * 0.21;

        new Promise((res) => {
          this.timer = setInterval(() => {
            if (numOne >= 25) {
              //move the rocket up, and stop at top: 25%
              numOne -= 1;
            }
            if (num <= 63) {
              num += 4;
            }
            backgroundCurrTop -= 35;
            buttonTop.style.top = num + "%";
            // rocketTop.style.top = numOne + "%";
            backgroundScrollTop.scrollTop = backgroundCurrTop;
            this.rocketFlameAnimated = true;
            this.playAudio();
            // calculate the % of the total height, and convert into px for background position
            // second  stop is at 50% (50% from top)
            if (backgroundCurrTop <= backgroundScrollTop.scrollHeight * 0.0) {
              clearInterval(this.timer);
              this.rocketFlameAnimated = false;
              this.playAudio(false);
              res();
            }
          }, 50);
        }).then(() => {
          let num = 63;
          let timerOne = setInterval(() => {
            num -= 4;
            buttonTop.style.top = num + "%";
            if (num < 43) {
              clearInterval(timerOne);
              this.control += 1;
            }
          }, 50);
        });
      } else if (this.control == 9) {
        this.isLastStep = true;
        console.log("== control 9");
        let num = 39;
        let numOne = 25;
        backgroundCurrTop = 0;
        playCorrectSound();
        startConfetti();
        this.playAudio(false);

        new Promise((res) => {
          this.timer = setInterval(() => {
            //move the rocket up, and stop at top: 25%
            numOne += 1;
            if (num <= 63) {
              num += 4;
            }
            rocketTop.style.bottom = numOne + "%";
            backgroundCurrTop -= 35;
            buttonTop.style.top = num + "%";
            // rocketTop.style.top = numOne + "%";
            backgroundScrollTop.scrollTop = backgroundCurrTop;
            this.rocketFlameAnimated = true;
            this.playAudio();

            // calculate the % of the total height, and convert into px for background position
            // second  stop is at 50% (50% from top)
            this.fadeOutRocket = true;

            if (numOne >= 100) {
              clearInterval(this.timer);
              this.rocketFlameAnimated = false;
              this.playAudio(false);
              res();
            }
          }, 50);
        });
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    playAudio(play = true) {
      if (play == true) {
        this.$refs.audioBox.play();
        console.log("play audio");
      } else {
        this.$refs.audioBox.pause();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.rocket-game-container {
  position: relative;
  .customlesson-finger {
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 49%;
    z-index: 1007;
    cursor: pointer;
    img {
      width: 15%;
    }
  }
  .customlesson-content-five {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .picture-one {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      border-radius: 58px;
      position: relative;
      display: inline-block;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
      .picture-content-rocket-content {
        position: fixed;
        // top: 85%;

        display: flex;
        left: 20%;
        width: 20%;
        z-index: 1;
        img {
          width: 100%;
        }

        // very large screen
        left: 25%;
        width: 10%;
        bottom: 3%;
        @media screen and (max-width: 2200px) {
          // external screen
        }
        @media screen and (max-width: 1500px) {
          // laptop
        }
        @media screen and (max-width: 1300px) {
          // ipad
          width: 15%;
          left: 25%;
          bottom: -3%;
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .picture-content-rocket-top {
      position: absolute;
      top: 10%;
      width: 20%;
      z-index: 1001;
      img {
        width: 100%;
      }
    }
    .picture-bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        z-index: 3;
        width: 100%;
        border-radius: 58px;
      }
      .picture-bottom-rocket-bottom {
        position: absolute;
        bottom: 0%;
        width: 20%;
        z-index: 1001;
        img {
          width: 100%;
        }
      }
      .picture-bottom-control-button-top {
        position: absolute;
        top: 39%;
        width: 10%;
        height: 50%;
        z-index: 3;
        border-radius: 10px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .picture-bottom-control-button-bottom {
        position: absolute;
        bottom: 18%;
        width: 10%;
        height: 50%;
        z-index: 1009;
        img {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }

    .customlesson_bottom_left_img_two {
      position: absolute;
      bottom: -1%;
      right: -1%;
      width: 20%;
      border-top-left-radius: 58px;
      text-align: center;
      z-index: 1005;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .customlesson_bottom_left_img_one {
      position: absolute;
      bottom: -6%;
      right: -2%;
      width: 17%;
      border-top-left-radius: 58px;
      text-align: center;
      z-index: 1006;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
  }
  .rocket {
  }
  .rocket-flame {
    position: absolute;
    bottom: 0;
    left: 0;

    &.rocketFlameAnimated {
      -webkit-animation: animatedFlame 2s infinite alternate-reverse;
      -moz-animation: animatedFlame 2s infinite alternate-reverse;
      animation: animatedFlame 2s infinite alternate-reverse;
    }

    @keyframes animatedFlame {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      33% {
        opacity: 0.6;
        transform: scale(1.4);
      }

      50% {
        opacity: 1;
        transform: scale(1);
      }
      77% {
        opacity: 0.6;
        transform: scale(1.4);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .glow {
    animation: glow 1s infinite alternate;
  }
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px #f4d737;
    }
    to {
      box-shadow: 0 0 10px 10px #f4d737, 0 0 1px 1px #f4d737 inset;
    }
  }
}

@keyframes fadeOutRocket {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOutRocket {
  -webkit-animation: fadeOutRocket 1.5s ease-in forwards;
  -moz-animation: fadeOutRocket 1.5s ease-in forwards;
  animation: fadeOutRocket 1.5s ease-in forwards;
}
</style>

<style lang="scss">
.pageBottomCornerButton {
  z-index: 9 !important;
}
</style>

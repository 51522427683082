<template>
  <div class="drag-game layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @randomClick="changeOrder"
    ></PageButton>
    <div class="drag-game-content">
      <div class="picture-envelope">
        <div class="content-picture-switching">
          <div
            class="lesson-one"
            v-for="(item, index) in envelopeList"
            :key="index"
            :class="
              ({
                notShow: showIndex === item.key,
              },
              'lesson' + item.key)
            "
          >
            <img
              class="switchImg"
              :src="item.picture"
              alt=""
              draggable="true"
              @dragstart.stop="drag($event, item)"
              @drop.stop="drop($event)"
              @dragend="handleDragend"
              :class="
                ({
                  dispear: item.isDispear,
                  'drag-img-envelope-4': dragNum === 4,
                },
                'switchImg' + item.key)
              "
            />
          </div>
        </div>
      </div>
      <div class="picture-mailbox">
        <div class="content-picture-switching">
          <div
            class="lesson-one"
            v-for="(item, index) in mailboxList"
            :key="index"
            draggable="true"
            @drop.stop="drop($event, item)"
            @dragover.stop="allowDrop($event)"
          >
            <img
              src="@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"
              alt=""
              :class="{ 'drag-img-mailbox-4': dragNum === 4 }"
            />
            <div class="word" :class="'word' + item.value">
              <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  props: {
    envelopeList: {
      type: Array,
      require: true,
    },
    mailboxList: {
      type: Array,
      require: true,
    },
    dragNum: {
      type: Number,
      require: true,
    },
  },
  components: {
    PageButton,
  },
  data() {
    return {
      lock: false,
      ramdomIndex: null,
      currentDom: null,
      currentKey: null,
      originDom: null,
      finishNum: 0,
      dragSocketInfo: {},
      checkInfo: {},
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "repeat",
            startNumberRange: 1,
            endNumberRange: this.dragNum,
            repeatBtn: true,
          },
        ],
      },
      checkDragValue: false,
      dragSocketInfoValue: false,
      showIndex: null,
    };
  },
  mounted() {
    this.$bus.$on("clickRandomBtn", (value) => {
      this.$emit("socketRandom", value);
      this.bottomCornerButtonList.buttons[0].type = "look";
      this.lock = true;
    });
    this.$bus.$on("dragEnvelope", ({ currentKey }) => {
      this.originDom = document.getElementsByClassName(
        `lesson${currentKey}`
      )[0];
      this.currentDom = document.getElementsByClassName(
        `switchImg${currentKey}`
      )[0];
      this.currentKey = currentKey;
      this.dragSocketInfoValue = true;
    });
    this.$bus.$on("checkDrag", ({ item }) => {
      let event = {};
      event.target = document.getElementsByClassName(`word${item.value}`)[0];
      this.drop(event, item);
      this.checkDragValue = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickRandomBtn");
    this.$bus.$off("dragEnvelope");
    this.$bus.$off("checkDrag");
    clearTimeout(this.timer);
  },
  watch: {
    finishNum(value) {
      if (value === this.dragNum) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
    },
    dragSocketInfo: {
      handler(value) {
        if (!this.dragSocketInfoValue) {
          const data = {
            clickType: 2001002,
            data: { value },
            text: "DragGameBtn drag点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    checkInfo: {
      handler(value) {
        if (!this.checkDragValue) {
          const data = {
            clickType: 2001003,
            data: { value },
            text: "DragGameBtn check点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleDragend() {
      this.showIndex = null;
    },
    changeOrder() {
      if (this.lock) {
        return;
      }
      console.log("+++++++++++++++++++++");
      this.$emit("randomOrder");
      const data = {
        clickType: 2001001,
        data: { value: this.envelopeList },
        text: "DragGameBtn点击socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      this.bottomCornerButtonList.buttons[0].type = "look";
      this.lock = true;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    drag(event, item) {
      console.log(event, item,2222)
      this.showIndex = item.key;
      this.dragSocketInfoValue = false;
      this.originDom = event.currentTarget.parentNode;
      this.currentDom = event.currentTarget;
      this.currentKey = item.key;
      this.dragSocketInfo = {
        currentKey: this.currentKey,
        ramdomIndex: Math.random(),
      };
    },
    drop(event, item) {
      this.checkDragValue = false;
      this.checkInfo = {
        event: {
          target: event.target,
        },
        ramdomIndex: Math.random(),
        item,
      };
      event.target.appendChild(this.currentDom); // 传入信封
      if (this.currentKey === item.value) {
        this.finishNum++;
        playCorrectSound(true, false);
      } else {
        setTimeout(() => {
          event.target.removeChild(this.currentDom); // 传入信封
          this.originDom.appendChild(this.currentDom);
          event.target.parentElement.className += " shake";
          playCorrectSound(false);
          setTimeout(() => {
            event.target.parentElement.className =
              event.target.parentElement.className.replace("shake", "");
          }, 1000);
        }, 200);
      }
    },

    allowDrop(event) {
      event.preventDefault();
    },
    startConfetti() {
      var n = new Audio(this.success_audio_sound);
      n.src = this.success_audio_sound;
      n.play();
      var myCanvas = document.getElementById("confettiCanvas");
      var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      });
      var end = Date.now() + 0.5 * 1000;

      // go Buckeyes!
      var colors = ["#ce4c3f", "#224e96", "#ffffff"];

      (function frame() {
        myConfetti({
          particleCount: 2,
          spread: 55,
          colors: colors,
        });
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    },
  },
};
</script>
<style scoped lang="scss">
.switchImg {
  -webkit-user-drag: auto !important;
}

.drag-game {
  .drag-game-content {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .picture-envelope {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content-picture-switching {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .lesson-one {
          width: 45%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            height: 100%;
            width: 50%;
          }
        }
        .notshow {
          opacity: 0;
        }
      }
    }
    .picture-mailbox {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content-picture-switching {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .lesson-one {
          width: 45%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .lesson-one-img {
            width: 50%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -20%;
            z-index: 1002;
            img {
              width: 50%;
            }
          }
          img {
            width: 60%;
            // height: 100%;
          }
          .word {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            position: absolute;
            top: 6%;
            span {
              color: #fff;
              // font-family: "TheItisCpinyin-LIGHT";
            }
            .switchImg {
              height: 30%;
              position: absolute;
              top: 4%;
              left: 20%;
            }
            .switchImg-drag-4 {
              height: 30%;
              position: absolute;
              top: 4%;
              left: 10%;
            }
          }
        }
      }
    }
    .customlesson_bottom_left_img_two {
      position: absolute;
      bottom: -1%;
      right: 0;
      width: 20%;
      border-top-left-radius: 58px;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .customlesson_bottom_left_img_one {
      position: absolute;
      bottom: -2%;
      right: -1%;
      width: 18%;
      img {
        width: 100%;
      }
    }
    .customlesson-small-picture {
      position: absolute;
      right: 0;
      top: 0;
      width: 8%;
      background-color: #d87065;
      border-top-right-radius: 52px;
      border-bottom-left-radius: 52px;
      border-bottom: 8px solid #a53d32;
      .small-picture {
        margin-top: 10px;
        background-color: #cd4c3f;
        border-top-right-radius: 52px;
        border-bottom-left-radius: 46px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        .small-picture-one {
          margin-top: 30px;
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        .small-picture-one:nth-child(3) {
          margin-bottom: 20px;
          padding: 6px;
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    // .customlesson-small-picture :hover{
    //   .small-picture{
    //       background-color:#A53D32 ;
    //       border-bottom:8px solid #A53D32;
    //     }
    // }
    .customlesson-conter {
      width: 50%;
      text-align: center;
      .customlesson-ont {
        height: 20%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .customlesson-img {
          img {
            width: 90%;
          }
        }
      }
      .customlesson_one {
        margin: auto;
        width: 100%;
        height: 20%;
        display: flex;
        .customlesson_two {
          width: 50%;
          height: 100%;
          margin-right: 10px;
          cursor: default;
          img {
            width: 70%;
          }
        }
      }
    }
  }
  .confettiCanvas {
    z-index: 900;
    position: absolute;
  }
  .drag-img-mailbox-4 {
    width: 80% !important;
  }
  .drag-img-envelope-4 {
    width: 60% !important;
  }
}
</style>

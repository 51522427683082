var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rocket-game-container layout-border" }, [
    _c("audio", { ref: "audioBox", attrs: { src: _vm.audioUrl } }),
    _c(
      "div",
      { staticClass: "customlesson-content-five position-relative" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: { clickNext: _vm.nextPage },
        }),
        _c(
          "div",
          {
            ref: "pictureOne",
            staticClass: "picture-one",
            attrs: { id: "space-background-scroll" },
          },
          [
            _c("img", { ref: "content", attrs: { src: _vm.bcgImg, alt: "" } }),
            _c(
              "div",
              {
                ref: "rocketTop",
                staticClass: "picture-content-rocket-content",
                class: { fadeOutRocket: _vm.fadeOutRocket },
              },
              [
                _c("img", {
                  staticClass: "rocket",
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G09-rocket/rocket.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  staticClass: "rocket-flame",
                  class: {
                    rocketFlameAnimated: _vm.rocketFlameAnimated,
                    isHidden: !_vm.showFlames,
                  },
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G09-rocket/rocket-flame.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "picture-bottom" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/16-GAMES/G09-rocket/control-table.svg"),
              alt: "",
            },
          }),
          _c(
            "div",
            {
              ref: "buttonTop",
              staticClass: "picture-bottom-control-button-top glow",
              on: { click: _vm.controlClick },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G09-rocket/control.svg"),
                  alt: "",
                },
              }),
            ]
          ),
        ]),
      ],
      1
    ),
    _vm.control == 0
      ? _c("div", { staticClass: "customlesson-finger" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/16-GAMES/G09-rocket/hand.svg"),
              alt: "",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }